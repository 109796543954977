<template>
  <dialog class="subscription-expired-dialog" ref="dialogRef">
    <div class="subscription-expired">
      <header>
        <h1>{{ $t("loginForm.subscriptionExpired.header") }}</h1>
      </header>
      <div class="dialog-content">
        <p>{{ $t("loginForm.subscriptionExpired.content") }}</p>
      </div>
      <Button
        theme="red"
        :link="true"
        :to="linkToSubscriptions"
        class="renew-subscription-btn"
        :label="$t('loginForm.subscriptionExpired.renewSubscription')"
      />
    </div>
  </dialog>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import Button from "@/components/Button.vue";
import { useStore } from "vuex";

const store = useStore();

const isSubscriptionExpired = computed(
  () => store.state.auth.isSubscriptionExpired
);

const linkToSubscriptions = computed(() => `${store.config?.portalLink}/Subscriptions`);

const dialogRef = ref(null);

watch(isSubscriptionExpired, (value) => {
  if (value) {
    dialogRef.value.showModal();
  } else {
    dialogRef.value.close();
  }
});
</script>

<style lang="scss" scoped>
.subscription-expired-dialog {
  padding: 20px;
  border-radius: 10px;
  margin: auto;

  header {
    padding: 5px 0;
  }

  .dialog-content {
    padding: 12px 0;
  }

  p {
    font-size: 1.4em;
  }

  .renew-subscription-btn {
    margin-top: auto;
  }

  &::backdrop {
    opacity: 0.8;
    background-color: black;
    backdrop-filter: blur(5px);
  }
}
</style>
